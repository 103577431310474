.skill-level{
  span {
    padding:0 0.15em;
    font-size: 0.55em;
  }
  span.incomplete {
    //color: rgba(0,0,0,0.33);
  }
  span.completed {
    //background:#000;
  }
}