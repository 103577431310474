
.book-header {
  margin:10px 0;
  font-size:1.15em;
  .book-author {
    font-style: italic;
  }
}



